

























import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import * as SchoolApi from "@/api/school";
import * as UserApi from "@/api/users";
import { ExamSchool, User } from "@/tool/_class";
import * as _ from "lodash";
import { uuid } from "@/tool";
//import * as hash from "hash-sum";
//const hash = require("hash-sum");

class UserSchoolAuth {
  userSchoolAuthId: string = uuid();
  userId: string = "";
  schoolId: string = "";
}

@Component({
  name: "UserSchoolAuthEditor",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  private title: string = "学校授权";
  private schools: Array<ExamSchool> = [];
  private authSchools: Array<string> = [];
  private users: User[] = [];
  private get userIds(): string[] {
    if (this.$route.query.userIds) {
      return this.$route.query.userIds as string[];
    }
    return [];
  }
  private get userSchoolAuthTo(): string {
    if (this.$route.query.userSchoolAuthTo) {
      return this.$route.query.userSchoolAuthTo as string;
    }
    return "right";
  }

  private loading: boolean = false;
  private async __initData() {
    this.loading = true;
    Promise.all([
      SchoolApi.getSchoolList({
        pageSize: 9999,
        curPage: 1,
      }),
      UserApi.getUserSchoolAuthList({
        curPage: 1,
        pageSize: 9999,
        userId: this.userIds.length == 1 ? this.userIds[0] : "",
        userIds: this.userIds || [],
      }),
      UserApi.getUserList({
        curPage: 1,
        pageSize: 9999,
        userIds: this.userIds,
      }),
    ])
      .then((resps: any) => {
        this.schools.splice(0, this.schools.length);
        resps[0].data.items.forEach((element: any) => {
          let item: ExamSchool = new ExamSchool();
          _.merge(item, element);
          this.schools.push(item);
          console.log(`school item: ${JSON.stringify(item)}`);
        });
        //
        this.authSchools.splice(0, this.authSchools.length);
        switch (this.userSchoolAuthTo) {
          case "left": {
            resps[1].data.items.forEach((element: any) => {
              const school = this.schools.find((school: ExamSchool) => {
                return school.schoolId == element.schoolId;
              });
              if (school && this.authSchools.indexOf(school.schoolId) < 0) {
                this.authSchools.push(school.schoolId);
              }
            });
            break;
          }
          case "right": {
            this.schools.forEach((element: ExamSchool) => {
              const tmpArr: any[] = resps[1].data.items.filter((value: any) => {
                return value.schoolId == element.schoolId;
              });
              if (tmpArr.length == this.userIds.length) {
                this.authSchools.push(element.schoolId);
              }
            });
            break;
          }
        }
        //
        this.users = resps[2].data.items;
        switch (this.users.length) {
          case 0: {
            this.title = "无";
            break;
          }
          case 1: {
            this.title = `学校授权 - ${this.users[0].username}`;
            break;
          }
          default: {
            this.title = `学校授权 - ${this.users[0].username}...等${this.users.length}个用户`;
          }
        }

        //
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  private renderContentFunc(h: any, option: any) {
    let schoolSections: string[] = [];
    if (option.primarySchool) {
      schoolSections.push("小");
    }
    if (option.middleSchool) {
      schoolSections.push("中");
    }
    if (option.highSchool) {
      schoolSections.push("高");
    }
    return h("div", {}, [
      h(
        "span",
        {
          attrs: {
            title: `${option.schoolName}${option.registered ? "" : "(未注册)"}`,
          },
          style: {
            color: option.registered ? "black" : "red",
          },
        },
        `${option.schoolName}[${schoolSections.toString()}]`
      ),
      h(
        "span",
        {
          style: {
            float: "right",
            color: "#8492a6",
            fontSize: "13px",
          },
        },
        option.areaName
      ),
    ]);
    /*
    return h('span', {
      attrs: {
        title: option.schoolName
      }
    }, option.schoolName);
    */
  }

  private async handleChange(
    value: string[],
    direction: string,
    movedKeys: string[]
  ) {
    for (let i = 0; i < this.userIds.length; i++) {
      let userId: string = this.userIds[i];
      for (let k = 0; k < movedKeys.length; k++) {
        const { data } = await UserApi.getUserSchoolAuthList({
          curPage: 1,
          pageSize: 1,
          userId: userId,
          schoolId: movedKeys[k],
        });

        switch (direction) {
          case "left": {
            if (data.items.length > 0) {
              await UserApi.delUserSchoolAuth(data.items[0].userSchoolAuthId);
            }
            break;
          }
          case "right": {
            if (data.items.length <= 0) {
              let auth: UserSchoolAuth = new UserSchoolAuth();
              auth.userId = userId;
              auth.schoolId = movedKeys[k];
              await UserApi.postUserSchoolAuth(auth);
            }
            break;
          }
        } //switch
      } //for k
    } //for i

    console.log(JSON.stringify(value), direction, JSON.stringify(movedKeys));
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }
  mounted() {
    this.__initData();
  }
}
