import { render, staticRenderFns } from "./user-school-auth.vue?vue&type=template&id=e2c3061c&scoped=true&"
import script from "./user-school-auth.vue?vue&type=script&lang=ts&"
export * from "./user-school-auth.vue?vue&type=script&lang=ts&"
import style0 from "./user-school-auth.vue?vue&type=style&index=0&id=e2c3061c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2c3061c",
  null
  
)

export default component.exports